<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <v-alert
          dense
          height="34"
          class="bg-altuny-tonal altuny--text d-flex align-center"
          :border="$vuetify.rtl ? 'left' : 'right'"
        >
          <span class="text-h6">{{ apiData.length }}</span> {{ $t('Factory') }}
        </v-alert>
      </v-col>

      <v-col cols="auto">
        <v-btn
          class="ms-3"
          depressed
          small
          color="primary"
          @click="addNew()"
        >
          {{ $t('Add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col
        v-for="(data, index) in apiData"
        :key="data.id"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="2"
      >
        <v-card
          flat
          outlined
          class="rounded-lg"
        >
          <v-card-text>
            <v-row
              align="center"
              style="position: relative"
            >
              <div
                style="position: absolute; top: 2px; opacity: 0.7"
                :style="$vuetify.rtl ? 'left: 4px' : 'right: 4px'"
              >
                #{{ index + 1 }}
              </div>
              <v-col
                cols="auto"
                class="px-1"
                style="position: relative"
              >
                <v-avatar size="55">
                  <img :src="`${data.logo}?token=${$_auth_token()}`">
                </v-avatar>
              </v-col>
              <v-col cols>
                <div class="d-flex align-center">
                  <div class="text-h6 text-center w-100 font-weight-bold">
                    {{ data[`name_${$i18n.locale}`] }}
                  </div>
                </div>

                <div class="d-flex justify-center mt-3">
                  <v-btn
                    small
                    depressed
                    class="me-3"
                    @click="updateRow(data)"
                  >
                    {{ $t('Edit') }}
                  </v-btn>

                  <v-btn
                    small
                    depressed
                    class3="bg-altuny-tonal altuny--text"
                    :to="`factory/${data.id}/`"
                  >
                    {{ $t('View') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!------------------- Dialogs ------------------->
    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="getData()"
      @update-table-data="getData()"
    />
  </div>
</template>

<script>
import AddEdit from './AddEdit.vue'
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  components: {
    AddEdit
  },

  data() {
    return {
      addEditDialog: false,
      formData: {},
      editMode: false,
      apiData: [],
    }
  },

  mounted() {
    this.$_section_title({ title: 'Factory & Occupation', icon: 'mdil-factory' })
    this.getData()
    this.getShareholdersList()
    this.getCompaniesList()
  },

  methods: {
    ...mapActions([
      'getShareholdersList',
      'getCompaniesList',
    ]),

    getData() {
      axios.get('factory/factory/').then((res) => {
        this.apiData = res.data
      })
    },

    addNew() {
      this.formData = {}
      this.editMode = false
      this.addEditDialog = true
    },

    updateRow(data) {
      axios.get(`factory/factory/${data.id}/edit/`).then((res) => {
        this.formData = { ...res.data }
        this.editMode = true
        this.addEditDialog = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>