<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="600px"
      transition="slide-y-transition"
    >
      <v-card class="rounded-lg dialog-card-border">
        <v-card-title class="d-flex justify-space-between align-center font-weight-bold">
          <div class="text-center col pa-0">
            {{ editMode ? $t('Update Factory & Occupation') : $t('Add New Factory & Occupation') }}
          </div>

          <v-btn
            fab
            small
            depressed
            @click="closeDialog()"
          >
            <v-icon class="mdil-28px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mb-6"
                >
                  <app-image-uploader
                    v-if="addEditDialog"
                    ref="appImageUploader"
                    width="130px"
                    height="130px"
                    classes="rounded-pill"
                    parent-classes="justify-center"
                    label="Logo"
                    :parent-image-url="formData.logo"
                    @image-selected="handleImageSelect"
                    @is-image-updated="formData.logo_updated = $event"
                  />
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Business ID Card')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.id_card"
                      :label="$t('Business ID Card')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Name Kurdish')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.name_ckb"
                      :label="`* ${$t('Name Kurdish')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Name Arabic')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.name_ar"
                      :label="`* ${$t('Name Arabic')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Name English')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.name_en"
                      :label="`* ${$t('Name English')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Type')"
                    rules="required"
                  >
                    <v-select
                      v-model="formData.type"
                      :label="`* ${$t('Type')}`"
                      :items="typeList"
                      :item-text="item => $t(item.text)"
                      outlined
                      dense
                      :error-messages="errors"
                      :menu-props="{ offsetY: true }"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Language')"
                    rules="required"
                  >
                    <v-select
                      v-model="formData.language"
                      :label="`* ${$t('Language')}`"
                      :items="languageList"
                      outlined
                      dense
                      :error-messages="errors"
                      :menu-props="{ offsetY: true }"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Owner Type')"
                    rules="required"
                  >
                    <v-select
                      v-model="formData.owner_type"
                      :label="`* ${$t('Owner Type')}`"
                      :items="ownerTypeList"
                      :item-text="item => $t(item.text)"
                      outlined
                      dense
                      :error-messages="errors"
                      :menu-props="{ offsetY: true }"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="formData.owner_type == 1"
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Owner')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.shareholder"
                      :label="`* ${$t('Owner')}`"
                      :items="shareholdersList"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="formData.owner_type == 2"
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Company')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.company"
                      :label="`* ${$t('Company')}`"
                      :items="companiesList"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Activity Type')"
                  >
                    <v-text-field
                      v-model="formData.activity_type"
                      :label="$t('Activity Type')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Date')"
                    rules="required"
                  >
                    <app-date-picker
                      v-model="formData.input_date"
                      :label="$t('Date')"
                      no-title
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Address')"
                    rules="required"
                  >
                    <v-text-field
                      v-model.number="formData.address"
                      :label="$t('Address')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
              >
                <v-icon left>
                  mdil-check
                </v-icon>
                <span class="pe-2">
                  {{ editMode ? $t('Save') : $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  data() {
    return {
      typeList: [
        { text: 'Factory', value: 1 },
        { text: 'Occupation', value: 2 },
      ],
      ownerTypeList: [
        { text: 'factory.Person', value: 1 },
        { text: 'factory.Company', value: 2 },
      ],
      languageList: [
        { text: 'کوردی', value: 'کوردی' },
        { text: 'عەرەبی', value: 'عەرەبی' },
        { text: 'English', value: 'English' },
      ],
    }
  },

  computed: {
    ...mapGetters([
      'shareholdersList',
      'companiesList',
    ]),
  },

  methods: {
    handleImageSelect(imageFIle) {
      this.formData.logo = imageFIle
      if (this.editMode) {
        let fd = new FormData()
        fd.append('logo', this.formData.logo)
        axios.post(`factory/factory/${this.formData.id}/update-logo/`, fd).then(() => {
          this.$emit('update-table-data')
          this.$_notify('Updated successfully')
        })
      }
    },

    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      if (this.editMode) {
        axios.put(`factory/factory/${this.formData.id}/`, this.formData).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('update-table-data')
        })
      } else {
        axios.post('factory/factory/', this.formData).then(res => {
          // if logo uploaded
          if (this.formData.logo) {
            let fd = new FormData()
            fd.append('logo', this.formData.logo)
            axios.post(`factory/factory/${res.data.id}/update-logo/`, fd)
          }

          this.$_notify('Added successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      }
    }
  },
}
</script>
