var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","transition":"slide-y-transition"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',{staticClass:"rounded-lg dialog-card-border"},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center font-weight-bold"},[_c('div',{staticClass:"text-center col pa-0"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Update Factory & Occupation') : _vm.$t('Add New Factory & Occupation'))+" ")]),_c('v-btn',{attrs:{"fab":"","small":"","depressed":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{staticClass:"mdil-28px mdil-rotate-45"},[_vm._v(" mdil-plus ")])],1)],1),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-6",attrs:{"cols":"12"}},[(_vm.addEditDialog)?_c('app-image-uploader',{ref:"appImageUploader",attrs:{"width":"130px","height":"130px","classes":"rounded-pill","parent-classes":"justify-center","label":"Logo","parent-image-url":_vm.formData.logo},on:{"image-selected":_vm.handleImageSelect,"is-image-updated":function($event){_vm.formData.logo_updated = $event}}}):_vm._e()],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Business ID Card'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Business ID Card'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.id_card),callback:function ($$v) {_vm.$set(_vm.formData, "id_card", $$v)},expression:"formData.id_card"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name Kurdish'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Name Kurdish'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.name_ckb),callback:function ($$v) {_vm.$set(_vm.formData, "name_ckb", $$v)},expression:"formData.name_ckb"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name Arabic'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Name Arabic'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.name_ar),callback:function ($$v) {_vm.$set(_vm.formData, "name_ar", $$v)},expression:"formData.name_ar"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name English'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Name English'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.name_en),callback:function ($$v) {_vm.$set(_vm.formData, "name_en", $$v)},expression:"formData.name_en"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":("* " + (_vm.$t('Type'))),"items":_vm.typeList,"item-text":function (item) { return _vm.$t(item.text); },"outlined":"","dense":"","error-messages":errors,"menu-props":{ offsetY: true }},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Language'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":("* " + (_vm.$t('Language'))),"items":_vm.languageList,"outlined":"","dense":"","error-messages":errors,"menu-props":{ offsetY: true }},model:{value:(_vm.formData.language),callback:function ($$v) {_vm.$set(_vm.formData, "language", $$v)},expression:"formData.language"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Owner Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":("* " + (_vm.$t('Owner Type'))),"items":_vm.ownerTypeList,"item-text":function (item) { return _vm.$t(item.text); },"outlined":"","dense":"","error-messages":errors,"menu-props":{ offsetY: true }},model:{value:(_vm.formData.owner_type),callback:function ($$v) {_vm.$set(_vm.formData, "owner_type", $$v)},expression:"formData.owner_type"}})]}}],null,true)})],1),(_vm.formData.owner_type == 1)?_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Owner'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":("* " + (_vm.$t('Owner'))),"items":_vm.shareholdersList,"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.shareholder),callback:function ($$v) {_vm.$set(_vm.formData, "shareholder", $$v)},expression:"formData.shareholder"}})]}}],null,true)})],1):_vm._e(),(_vm.formData.owner_type == 2)?_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":("* " + (_vm.$t('Company'))),"items":_vm.companiesList,"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.company),callback:function ($$v) {_vm.$set(_vm.formData, "company", $$v)},expression:"formData.company"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Activity Type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Activity Type'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.activity_type),callback:function ($$v) {_vm.$set(_vm.formData, "activity_type", $$v)},expression:"formData.activity_type"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-date-picker',{attrs:{"label":_vm.$t('Date'),"no-title":"","error-messages":errors},model:{value:(_vm.formData.input_date),callback:function ($$v) {_vm.$set(_vm.formData, "input_date", $$v)},expression:"formData.input_date"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Address'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", _vm._n($$v))},expression:"formData.address"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","depressed":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdil-check ")]),_c('span',{staticClass:"pe-2"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Save') : _vm.$t('Add'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }